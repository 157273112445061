export const driverNameMapping: any = {
  'Completion Rate - Product': 'Completion Rate - Product (%)',
  'Completion Rate - Sales': 'Completion Rate - Sales (%)',
  'Contacts per Account': 'Contacts per Account (#)',
  'Multi Thread Score': 'Multi Thread Score (#)',
  'Time w/ Customers': 'Time w/ Customers (Hrs/Qtr)',
  'Time w/ Partners': 'Time w/ Partners (Hrs/Qtr)',
  'Manager Rep 1:1 Frequency': 'Manager Rep 1:1 Frequency (Hrs/Qtr)',
  'Accounts Touched':'Accounts Touched (%)'
};
export const keyDriverData1 = [
  {
    name: 'Time With Customers',
    value: 11.5,
    global_max_value: 49.68,
    global_mean_value: 33.72,
  },
  {
    name: 'Follow Up Ratio',
    value: 5.8,
    global_max_value: 20.5,
    global_mean_value: 12.71,
  },
  {
    name: 'Technical Skills',
    value: 1.5,
    global_max_value: 5,
    global_mean_value: 3.45,
  },
];
export const keyDriverData2 = [
  {
    name: 'Pipeline Discipline',
    value: 32.5,
    global_max_value: 42,
    global_mean_value: 29.51,
  },
  {
    name: 'Time With Product Team',
    value: 25.65,
    global_max_value: 30.75,
    global_mean_value: 13.26,
  },
  {
    name: 'Technical Skills',
    value: 4.3,
    global_max_value: 5,
    global_mean_value: 3.45,
  },
];
export const keyDriverData3 = [
  {
    name: 'MEDPICC Score',
    value: 29.5,
    global_max_value: 99,
    global_mean_value: 60.61,
  },
  {
    name: 'Time With Product Team',
    value: 7.2,
    global_max_value: 30.75,
    global_mean_value: 13.26,
  },
  {
    name: 'Communication skills',
    value: 1.2,
    global_max_value: 5,
    global_mean_value: 3.45,
  },
];
export const keyDriverData4 = [
  {
    name: 'Pipeline Discipline',
    value: 32.5,
    global_max_value: 42,
    global_mean_value: 29.51,
  },
  {
    name: 'Time With Customers',
    value: 38.65,
    global_max_value: 49.68,
    global_mean_value: 33.69,
  },
  {
    name: 'Communication skills',
    value: 4.4,
    global_max_value: 5,
    global_mean_value: 3.45,
  },
];
export const keyDriverData5 = [
  {
    name: 'Time With Customers',
    value: 11.5,
    global_max_value: 49.68,
    global_mean_value: 33.72,
  },
  {
    name: 'Pipeline Discipline',
    value: 12.5,
    global_max_value: 42,
    global_mean_value: 29.51,
  },
  {
    name: 'Technical Skills',
    value: 1.5,
    global_max_value: 5,
    global_mean_value: 3.45,
  },
];
export const keyDriverData6 = [
  {
    name: 'MEDPICC Score',
    value: 71.5,
    global_max_value: 99,
    global_mean_value: 60.61,
  },
  {
    name: 'Time With Customers',
    value: 41.5,
    global_max_value: 49.68,
    global_mean_value: 33.72,
  },
  {
    name: 'Technical Skills',
    value: 4.1,
    global_max_value: 5,
    global_mean_value: 3.45,
  },
];
export const keyDriverData7 = [
  {
    name: 'Time With Customers',
    value: 41.5,
    global_max_value: 49.68,
    global_mean_value: 33.72,
  },
  {
    name: 'Communication skills',
    value: 4.4,
    global_max_value: 5,
    global_mean_value: 3.45,
  },
  {
    name: 'MEDPICC Score',
    value: 71.5,
    global_max_value: 99,
    global_mean_value: 60.61,
  },
];
export const keyDriverData8 = [
  {
    name: 'Technical Skills',
    value: 4.2,
    global_max_value: 5,
    global_mean_value: 3.45,
  },
  {
    name: 'MEDPICC Score',
    value: 71.5,
    global_max_value: 99,
    global_mean_value: 60.61,
  },
  {
    name: 'Time With Customers',
    value: 41.5,
    global_max_value: 49.68,
    global_mean_value: 33.72,
  },
];

export const ignoredReps = [
  '3e6bfd8e-8f0b-481c-95e9-866f79c22508',
  '39e0d97a-b985-4fc3-a32c-2e3d83b3a91f',
  '20b3649f-7e87-4972-8011-8651794b6aef',
  '55dfb408-2392-4662-983e-fbb11f9e20e5',
  '3e29ded4-86c2-424e-9da2-b1d48b1ef5a6',
  '6c3b4646-b7fc-4e43-bf5f-16a2cc0cbb4d',
  '1afd8f9e-907a-4a98-81a0-8bf39acffbca',
  '7c521aab-b1f7-4008-9326-152ae9cffa54',
  'c584c620-9a84-4d6a-8611-81fabe805c55',
  'f540c64b-9ebf-46c2-b774-18737c9b4490',
  'ec51e4a4-95f2-4e0e-a112-845b2bdfec83',
  'd175e973-f5cf-49dc-a5d9-68084032b7ad',
  '3102c684-2b52-4744-b283-fc914f083372',
  '36aa6ef4-c69b-411c-b969-9bf346800b50',
  'f3e1b2bd-38ec-4d49-99aa-acd8ce40bb4b',
  '5ccd1919-118f-4334-9575-3be4ccf9c68f',
  'c97dde18-d29f-465f-8b63-51f21cf9f3e8',
  '40033b38-cea0-4f6c-86db-cd114c1dc9a5',
  '7f8da2ed-ae3c-4772-b638-8479a52a2e31',
  'a5216519-fcb4-4c30-8bac-cd25655b5033',
  '82b1fa2c-b1c0-49ed-9cfc-283bfa7b8106',
  '3b3a1048-5bfc-4d69-b45e-3266d81c7323',
  '3160a328-bd3d-4803-9803-f9b474ff095e',
  'f9ee8caf-398f-4cd7-9f98-2f566265e83e',
  '1d962aaf-7fa9-4daf-80b9-66f9450fac59',
  '7d9f452a-66d5-4d22-bcab-70c7cdfc412d',
  '9e259352-65cf-4ef1-8ac0-4edfdfa9d7e9',
  'f0747863-0241-4b56-afd0-585a338ccd6f',
  '385e121c-4670-4f9a-820c-2d02eb20bae8',
  'de8fdca3-7211-4257-9a8e-213a79efefd2',
  'd206af4d-bdcc-42b8-9a4c-06421d4fbf51',
  '664ee783-0158-44b7-b731-642353eda149',
  '72289b1a-5c9f-4d8d-82ed-d3b0fa30800c',
  '9bc26db0-6495-43b7-8071-b84cb65fb068',
];
