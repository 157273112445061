import { COLORS } from '../plTheme';

export const roundNumber = (num: string) => {
  const parts = num.split(',');
  return parts.length > 1
    ? (
        Math.round(
          (parseInt(parts.join(''), 10) / Math.pow(1000, parts.length - 1)) *
            10,
        ) / 10
      )?.toString() + ['K', 'M', 'B'][parts.length - 2]
    : parts[0];
};

export const ProductColorMapping: { name: string; color: string }[] = [
  {
    name: 'asp',
    color: '#0055DC',
  },
  {
    name: 'edge',
    color: '#5B8FF9',
  },
  {
    name: 'cloudsecure',
    color: 'RGBA(0, 88, 227, 0.3)',
  },
  {
    name: 'others',
    color: 'RGBA(126, 133, 142, 0.13)',
  },
];

export const formatHireDate = (date: string | undefined) => {
  if (date) {
    let formatedDate = date?.includes('-') ? date : +date;
    const dateObject = new Date(formatedDate);
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const year = dateObject.getFullYear() % 100;
    return `(DoH: ${month}/${year})`;
  } else return '';
};
